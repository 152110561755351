import React from "react";
import Home from "./components/Home";


function App() {
  return (
    <div className="opacity-100">
      <Home />

    </div>
  );
}

export default App;
